import React from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const InfoIcon = ({ children, icon, className }) => (
  <p
    className={classNames(
      "is-size-6 is-flex is-align-items-center",
      className || ""
    )}
  >
    <span className="icon mr-1">
      <FontAwesomeIcon icon={icon} />
    </span>
    <span>{children}</span>
  </p>
)

export default InfoIcon
