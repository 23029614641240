import React from "react"

import InfoIcon from "./InfoIcon"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import { faBirthdayCake, faPhone } from "@fortawesome/free-solid-svg-icons"

const PersonalDetails = ({ user }) => {
  if (user?.birthday) {
    let { month, date, year } = user?.birthday
    user.patientBirthday = `${month?.value || month} ${
      date?.value || date
    }, ${year}`
  }

  return (
    <Section
      title="Personal Details"
      addOns={{
        right: <EditDetailsButton route="/profile/edit" />,
      }}
    >
      <h4 className="has-text-black">
        {user?.firstName} {user?.lastName}
      </h4>
      <InfoIcon icon={faPhone}>Mobile Number: {user?.mobileNumber}</InfoIcon>
      <InfoIcon icon={faBirthdayCake}>
        Birthday: {user?.patientBirthday}
      </InfoIcon>
    </Section>
  )
}

export default PersonalDetails
