import { Link } from "gatsby"
import classNames from "classnames"
import React, { useContext } from "react"

import AddressInfoIcon from "./AddressInfoIcon"
import styles from "../utils/profile.module.scss"
import { AppContext } from "../../../context/AppContext"
import { makeDefaultAddress } from "../services/addresses"

import { faMapMarkedAlt, faHome } from "@fortawesome/free-solid-svg-icons"

const AddressContainer = ({
  index,
  address,
  handleClick,
  setAddresses,
  activeAddress,
}) => {
  const { dispatch } = useContext(AppContext)

  let completeAddress = `${address.streetAddress} ${
    address.barangay ? address.barangay : ""
  } ${address.city}, ${address.province} ${address.notes ? address.notes : ""}`

  let handleSetPrimaryAddress = () => {
    makeDefaultAddress({
      index,
      callback: (addressDocument) => {
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message: `Successfully updated primary address`,
            color: "success",
          },
        })
        setAddresses(addressDocument)
      },
    })
  }

  let primaryAddress = address.primary && (
    <span className="ml-1 tag is-rounded">Default</span>
  )

  return (
    <div
      className={classNames("box is-shadowless", styles["box"], {
        "has-background-light": activeAddress === index,
        [styles["box__clickable"]]: !!handleClick,
      })}
      onClick={() => {
        if (handleClick) handleClick(index)
      }}
      onKeyDown={() => {
        if (handleClick) handleClick(index)
      }}
      role="button"
      tabIndex={0}
    >
      <AddressInfoIcon
        icon={faHome}
        label="Address Type"
        value={address.type}
        addOn={primaryAddress}
      />
      <AddressInfoIcon icon={faMapMarkedAlt} value={completeAddress} />
      <p className="is-size mt-2">
        <div className="buttons are-small">
          <Link
            className="button is-info has-text-white"
            to="/profile/edit-address"
            state={{ address: { ...address, index }, index: index }}
          >
            Edit
          </Link>
          {!address.primary && (
            <button
              type="button"
              className="button"
              onClick={handleSetPrimaryAddress}
            >
              Set as primary
            </button>
          )}
        </div>
      </p>
    </div>
  )
}

export default AddressContainer
