import React from "react"
import { Link } from "gatsby"

import Section from "elements/Section"
import AddressContainer from "../Addresses/AddressContainer"

const Addresses = ({ addresses, setAddresses }) => {
  const handleDeleteCallback = (addressDocument) => {
    setAddresses(addressDocument)
  }

  return (
    <Section title="Addresses">
      {addresses.map((address, index) => {
        return (
          <AddressContainer
            address={address}
            index={index}
            setAddresses={setAddresses}
            handleDeleteCallback={handleDeleteCallback}
          />
        )
      })}
      <Link
        to="/profile/add-address"
        state={{ nextRoute: "/profile", backRoute: "/profile" }}
      >
        + Add new address
      </Link>
    </Section>
  )
}

export default Addresses
