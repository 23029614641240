import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AddressInfoIcon = ({ label, value, icon, addOn }) => {
  return (
    <p className="is-size-6">
      <span className="icon mr-1">
        <FontAwesomeIcon icon={icon} />
      </span>
      {label && `${label}: `}
      {value} {addOn}
    </p>
  )
}

export default AddressInfoIcon
