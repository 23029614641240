import React from "react"

import InfoIcon from "./InfoIcon"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons"

const AccountDetails = ({ user }) => {
  return (
    <Section
      title="Account Details"
      addOns={{
        right: (
          <EditDetailsButton route="/profile/update-password">
            Update Password
          </EditDetailsButton>
        ),
      }}
    >
      <InfoIcon icon={faEnvelope}>{user?.email}</InfoIcon>
      <InfoIcon icon={faLock}>
        &bull; &bull; &bull; &bull; &bull; &bull; &bull;
      </InfoIcon>
    </Section>
  )
}

export default AccountDetails
