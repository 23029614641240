import React, { useEffect, useState } from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import Addresses from "./components/Addresses"
import AccountDetails from "./components/AccountDetails"
import PersonalDetails from "./components/PersonalDetails"

import { getSignedInUser } from "../Auth/services/user"

const Profile = (props) => {
  const [user, setUser] = useState({})
  const [addresses, setAddresses] = useState([])

  let { pageContext } = props
  let { module } = pageContext

  useEffect(() => {
    let userData = getSignedInUser()?.userData
    let addressesList = getSignedInUser()?.addresses?.addresses || []

    setUser(userData)
    setAddresses(addressesList)
  }, [])

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={module.subtitle}
      isPrivate
      isPatient
    >
      <Container isCentered>
        <PersonalDetails user={user} />
        <Addresses setAddresses={setAddresses} addresses={addresses} />
        <AccountDetails user={user} />
      </Container>
    </Layout>
  )
}

export default Profile
